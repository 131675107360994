import { Link } from "gatsby";
import React from "react";


const IndexPage = ():JSX.Element => {
  return (
    <div style={{textAlign: "center"}} >
      <h1 >This is bad 😓</h1>
      <p>The page you are looking for cannot be found anywhere </p>
      <Link to="/"> Click here to go to the main page </Link>
    </div>
  );
};

export default IndexPage;